<template>
    <div>
        <v-container fluid>
            <v-toolbar elevation="1" dark color="primary">
                <v-toolbar-title>
                    Detalle de solicitud {{$route.params.id}}
                </v-toolbar-title>
                <v-spacer/>
                <v-btn
                    icon
                    dark
                    @click="guarda"
                    :loading="loadingBtn"
                >
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <v-btn
                    icon
                    dark
                    @click="$router.go(-1)"
                >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
            </v-toolbar>
            <v-sheet elevation="2" class="pa-3">
                <v-row class="mb-2">

                    <v-col md=4 cols=12>
                        <v-text-field
                            v-model="datosNuevos.descripcionBreve"
                            label="Descripción breve"
                            readonly
                            dense
                            hide-details
                            outlined
                            rounded
                        />
                    </v-col>

                    <v-col md=3 cols=12>
                        <v-text-field
                            v-model="detalleGravedad"
                            label="Gravedad"
                            readonly
                            dense
                            hide-details
                            outlined
                            rounded
                        />
                    </v-col>

                    <v-col md=3 cols=12>
                        <v-select
                            v-model="datosNuevos.estado"
                            :items="estados"
                            label="Estado"
                            dense
                            hide-details
                            rounded
                            filled
                        />
                    </v-col>

                    <v-col md=2 cols=12>
                        <v-text-field
                            v-model="datosNuevos.folio"
                            label="Folio de reporte"
                            dense
                            hide-details
                            rounded
                            filled
                        />
                    </v-col>

                    <v-col md=2 cols=12>
                        <v-text-field
                            v-model="datosNuevos.paciente"
                            label="Expediente"
                            dense
                            hide-details
                            readonly
                            outlined
                            rounded
                        />
                    </v-col>
                    <v-col md=4 cols=12>
                        <v-text-field
                            v-model="datosNuevos.pacienteNombre"
                            label="Paciente"
                            dense
                            hide-details
                            readonly
                            outlined
                            rounded
                        />
                    </v-col>
                    <v-col md=2 cols=12>
                        <v-text-field
                            v-model="datosNuevos.pacienteFechaNacimiento"
                            label="Fecha de nacimiento"
                            dense
                            hide-details
                            readonly
                            outlined
                            rounded
                        />
                    </v-col>
                    <v-col md=1 cols=12>
                        <v-text-field
                            v-model="datosNuevos.pacienteEdad"
                            label="Edad"
                            dense
                            hide-details
                            readonly
                            outlined
                            rounded
                        />
                    </v-col>

                    <v-col md=2 cols=12>
                        <v-text-field
                            v-model="datosNuevos.pacienteCurp"
                            label="C.U.R.P."
                            dense
                            hide-details
                            readonly
                            outlined
                            rounded
                        />
                    </v-col>

                    <v-col md=1 cols=12>
                        <v-text-field
                            v-model="datosNuevos.cama"
                            label="Cama"
                            dense
                            hide-details
                            readonly
                            outlined
                            rounded
                        />
                    </v-col>
                    <v-col md=3 cols=12>
                        <v-text-field
                            class="my-2"
                            v-model="datosNuevos.fecha"
                            label="Fecha del reporte"
                            readonly
                            dense
                            hide-details
                            outlined
                            rounded
                        />
                    </v-col>

                    <v-col md=3 cols=12>
                        <v-text-field
                            class="my-2"
                            v-model="datosNuevos.turno"
                            label="Turno"
                            readonly
                            dense
                            hide-details
                            outlined
                            rounded
                        />
                    </v-col>

                    <v-col md=6 cols=12>
                        <v-text-field
                            v-model="datosNuevos.personaProporcionaInfo"
                            label="Persona que proporciona información"
                            readonly
                            dense
                            hide-details
                            outlined
                            rounded
                        />
                    </v-col>

                    

                    <v-col cols=6>
                        <v-select
                            v-model="datosNuevos.implicado"
                            :items="datosPersonal"
                            label="Personal implicado"
                            hide-details
                            readonly
                            chips
                            multiple
                            outlined
                        />
                        <v-text-field
                            class="mt-6"
                            v-if='datosNuevos.implicado && datosNuevos.implicado.includes("99")'
                            v-model="datosNuevos.implicadoOtro"
                            label="Otro"
                            dense
                            hide-details
                            readonly
                            outlined
                        />
                    </v-col>

                    <v-col cols=6>
                        <v-select
                            v-model="datosNuevos.presencia"
                            :items="datosPersonal"
                            label="Personal que precencia"
                            hide-details
                            readonly
                            chips
                            multiple
                            outlined
                        />
                        <v-text-field
                            class="mt-6"
                            v-if='datosNuevos.presencia && datosNuevos.presencia.includes("99")'
                            v-model="datosNuevos.precenciaOtro"
                            label="Otro"
                            dense
                            hide-details
                            readonly
                        />
                    </v-col>
                    <v-col cols=12>
                        <v-textarea
                            v-model="datosNuevos.descripcionDetallada"
                            label="Descripción detallada"
                            dense
                            outlined
                            rounded
                            hide-details
                            readonly
                        />
                    </v-col>
                </v-row>

                <v-toolbar elevation="1" dark color="primary" dense>
                    <v-toolbar-title>
                        Actualizaciones
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-btn
                        icon
                        dark
                        @click="abreActualizacion = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-alert
                    v-if="totalMovimientos<1"
                    class="ma-2"
                    text
                    type="info"
                >
                    No hay actualizaciones
                </v-alert>
                <v-sheet v-for="(itm, id) in movimientos" :key="id" class="ma-2" elevation="2" rounded="lg">
                    <div class="pa-2" :style='{"white-space": "pre-wrap"}'>{{itm.detalles}}</div>
                    <v-divider/>
                    <div class="text-caption text-right pa-2">
                        {{itm.dateCreated}} por {{itm.crea}}
                    </div>
                </v-sheet>

            </v-sheet>
            <nueva-actualizacion
                :evento="datos.id"
                :dialogOpen.sync="abreActualizacion"
                @agregado="cargaAct"
            />
        </v-container>


    </div>
</template>

<script>
import { diff } from 'deep-object-diff'
export default {
    mounted(){
        this.carga()
        //this.cargaAct()
    },
    components:{
        'nueva-actualizacion':()=>import('@/views/calidad/adversos/ActualizacionEvento'),
    },
    data:()=>({
        loadingBtn:false,
        abreActualizacion:false,
        datos:{},
        datosNuevos:{},
        loading:false,

        loadingDetalles:false,
        movimientos:[],
        totalMovimientos:0,

        estados:[
            {value:1, text:"Solicitado"},
            {value:2, text:"Atendiendo"},
            {value:3, text:"Resuelto"},
            {value:99, text:"Cancelado"},
        ],
        datosPersonal:[
            {value:"1", text:"Médico"},
            {value:"2", text:"Enfermera"},
            {value:"3", text:"Camillero"},
            {value:"4", text:"Técnico"},
            {value:"5", text:"Personal en formación"},
            {value:"99", text:"Otro"},
        ],
    }),
    methods:{
        async carga(){
            this.loading = true
            try{
                let req = await this.$http({
                    method:'GET',
                    url: '/calidad/listAdversos',
                    params:{id:this.$route.params.id}
                })
                this.loading=false
                this.datos = req.data.root
                this.cargaAct()
                this.datosNuevos = {...this.datos}
                this.datosNuevos.implicado = this.datos.implicado.split(',')
                this.datosNuevos.presencia = this.datos.presencia.split(',')
            }catch(err){
                this.loading=false
                this.$store.dispatch('security/revisaError',err)
            }
            //console.log('cargando')
        },
        async cargaAct(){
            this.loadingDetalles = true
            //let params = {...this.params}
            //params.start = (this.optionsTable.page-1 )*this.optionsTable.itemsPerPage,
            //params.limit = this.optionsTable.itemsPerPage

            try{
                const req = await this.$http({
                    method:'GET',
                    url: '/calidad/listActualizaciones',
                    params:{evento:this.datos.id}
                })
                this.loadingDetalles=false
                this.movimientos = req.data.root
                this.totalMovimientos = req.data.total
            }catch(err){
                this.loadingDetalles=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        colorEstado(itm){
            let color = 'black'
            switch (itm){
                case 1:
                    color="blue"
                    break
                case 2:
                    color="orange"
                    break
                case 3:
                    color="green"
                    break
                case 99:
                    color="red"
                    break
            }
            return color
        },
        async guarda(){
            this.loadingBtn=true
            let params = diff(this.datos, this.datosNuevos)
            delete params.implicado
            delete params.presencia
            params.id = this.datos.id
            try{
                let req =await this.$http({
                    url:'/calidad/guardaEventoAdverso',
                    method:'POST',
                    data:params
                })
                this.loadingBtn=false
                if (req.data.estado){
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        text: 'Reporte guardado',
                    })
                    this.dialog = false
                    this.carga()
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Revise los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.loadingBtn=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        detalleGravedad(){
            const grav ={
                sin: "SIN DAÑO",
                baj: "BAJO",
                mod: "MODERADO",
                gra: "GRAVE",
                mue: "MUERTE"
            }
            return grav[this.datosNuevos.gravedad]
        }
    },

}
</script>

<style>

</style>